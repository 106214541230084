<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="导入" @click="toImport"></v-button>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="套餐名称"
          v-model="searchParam.title"
          placeholder="请输入套餐名称"
        ></v-input>
        <!-- <v-select clearable :options="scheduledTime" label="预定时间" /> -->
      </template>
      <template #operateSlot="scope">
        <v-button text="设置菜品" type="text" @click="setOption(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      v-model="dialogFormVisible"
      :title="dialogTitle"
      cancelTxt="返回"
      sureTxt="保存"
      @confirm="submit"
    >
      <el-form label-position="right" label-width="120px">
        <el-form-item label="套餐名称：">
          <v-input
            placeholder="请输入套餐名称"
            clearable
            class="filter-item"
            v-model="form.title"
          ></v-input>
        </el-form-item>
        <el-form-item label="图片">
          <v-upload
            :limit="1"
            :imgUrls.sync="form.imageUrls"
            tips="建议上传正方形图片"
          />
        </el-form-item>
        <el-form-item label="定价：">
          <v-input
            placeholder="请输入定价(单位：元)"
            clearable
            class="filter-item"
            v-model="form.price"
          ></v-input>
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import { mealListUrl, addMealUrl, delMealUrl } from "./api.js";
import { scheduledTime } from "./map.js";
import { Message, MessageBox } from "element-ui";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
export default {
  name: "canteenManage",
  data() {
    return {
      form: {
        id: null,
        title: "",
        imageUrls: "",
        price: "",
      },
      dialogTitle: "新增",
      dialogFormVisible: false,
      scheduledTime,
      tableUrl: mealListUrl,
      searchParam: {
        title: null,
      },
      headers: [
        {
          prop: "id",
          label: "套餐id",
        },
        {
          prop: "title",
          label: "套餐名称",
        },
        {
          prop: "imageUrls",
          label: "图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "price",
          label: "定价",
          formatter: (row, prop) => {
            return "￥" + row.price;
          },
        },
      ],
    };
  },
  created() {
    this.getToDay();
  },
  methods: {
    // 导入
    toImport() {
      this.$router.push({
        name: "canteenImport",
      });
    },
    back() {
      this.dialogFormVisible = false;
    },
    submit() {
      if (this.form.title == "") {
        this.$message.error("请输入套餐名称");
        return;
      }
      if (this.form.imageUrls == "") {
        this.$message.error("请上传套餐图片");
        return;
      }
      if (this.form.price == "") {
        this.$message.error("请输入套餐定价");
        return;
      }
      let params = {
        title: this.form.title,
        imageUrls: this.form.imageUrls,
        price: this.form.price,
      };
      this.$axios.post(`${addMealUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.dialogFormVisible = false;
          this.$message.success("操作成功！");
          this.$refs.list.search();
        }
      });
    },
    setOption(item) {
      this.$router.push({
        name: "setMenu",
        query: {
          id: item.id,
        },
      });
    },
    toAdd() {
      this.dialogFormVisible = true;
    },
    getToDay() {
      let date = new Date();
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      // this.searchParam.day = `${year}-${month}-${day}`;
    },
    // 删除
    toDelete(item) {
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
          };
          this.$axios
            .post(`${delMealUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
